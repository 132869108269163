import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/AboutUs";
import Contact from "./pages/ContactUs";
import Solution from "./pages/OurSolutions";
import Event from "./pages/Event";
import Gallery from "./pages/Gallery";
import ChampionShip from "./pages/ChampionShip";
import Information from "./pages/Information";
import WahooOpen from "./pages/WahooOpen";
import Rules from "./pages/Rules";
import ResortAndMarina from "./pages/ResortAndMarina";
import Register from "./pages/Register";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/our-solutions" element={<Solution />} />
        <Route path="/events" element={<Event />} />
        <Route path="/events/gallery" element={<Gallery />} />
        <Route path="/events/championship" element={<ChampionShip />} />
        <Route path="/events/wahoo" element={<Information />} />
        <Route path="/events/wahoo-open" element={<WahooOpen />} />
        <Route path="/events/rules" element={<Rules />} />
        <Route path="/events/resort-and-marina" element={<ResortAndMarina />} />
        <Route path="/events/Register" element={<Register />} />
      </Routes>
    </Router>
  );
}

export default App;
