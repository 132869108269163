import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Menu from "../assets/images/menu.png";
import Close from "../assets/images/close.png";

const MainNavbar = () => {
  const location = useLocation();

  return (
    <div className="main-navbar">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Link className="nav-anchor" to="/">
            <img src={Logo} alt="flyzone" width="190" />
          </Link>

          <button
            className="navbar-toggler custom-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="custom-toggler-icon">
              <span className="menu-icon">
                <img src={Menu} alt="Menu" width="50" />
              </span>
              <span className="close-icon">
                <img src={Close} alt="Close" width="30" />
              </span>
            </div>
          </button>
          {/* Navbar links, aligned to the right */}
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
              <div className="nav-links">
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/" && "active-link"
                  }`}
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/our-solutions" && "active-link"
                  }`}
                  to="/our-solutions"
                >
                  Our Solutions
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/events" && "active-link"
                  }`}
                  to="/events"
                >
                  Events
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/about-us" && "active-link"
                  }`}
                  to="/about-us"
                >
                  About us
                </Link>
                <button
                  className={`nav-btn ${
                    location.pathname === "/contact-us" && "active-link"
                  }`}
                >
                  <Link className="nav-anchor text-white" to="/contact-us">
                    Contact
                  </Link>
                </button>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MainNavbar;
