import React from "react";
import EventsNavbar from "../components/EventsNavbar";
import Footer from "../components/Footer";
import Line from "../assets/images/line.png";
import Fish from "../assets/images/information-group-image.png";
import EventInformationBg from "../assets/images/event-informat-bg.png";
import BlueMarineCoweLogo from "../assets/images/blue-marine-cowe-logo.png";
import OldBahamaBayLogo from "../assets/images/old-bahama-bay-logo.png";

const Information = () => {
  return (
    <div>
      {/* Hero section */}
      <section className="info-hero-container">
        <img className="info-bg" src={EventInformationBg} alt="fish" />
        <EventsNavbar />
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="info-des-container">
                <h1 className="title"> Tournament Information</h1>
                <img className="img-line" src={Line} alt="line" />
                <p className="des">
                  Fly Zone Fishing proudly announces the launch of the Blue
                  Marlin Cove Wahoo Open and Blue Marlin Cove Wahoo
                  Championship, a premier wahoo fishing tournament series set
                  against the stunning backdrop of West End, Bahamas. Scheduled
                  for
                  <strong style={{ fontWeight: "bold" }}>January 16th thru the 18th 2025</strong> and the
                  Championship on{" "}
                  <strong style={{ fontWeight: "bold" }}>February 13th thru 15th, 2025,</strong> this inaugural
                  series promises an exhilarating competition for anglers and a
                  captivating spectacle for spectators.
                  <br></br>
                  Fly Navarro, Tournament Director, expressed enthusiasm about
                  the upcoming event, stating, The Blue Marlin Cove Wahoo Open
                  and Blue Marlin Cove Wahoo Championship is poised to be a
                  game-changer in the world of wahoo fishing tournaments. Were
                  bringing together top-tier anglers and a stunning location for
                  an unforgettable experience.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="info-img-container">
                <img src={Fish} alt="fish" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="event-hotel-section">
        <div className="container">
          <div className="row">
            <h2>Dockage And Hotel</h2>
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="hetel-detail">
                    <p>
                      Once you sign up for the <b>Blue Marlin Cove</b> Wahoo
                      Open you will be provided with a promo code to book your
                      room and slip. To check availability, go to
                    </p>
                    <a
                      href="https://bluemarlincove.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="z-3"
                    >
                      Check Availability
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="img-container">
                    <img src={BlueMarineCoweLogo} alt="blue logo" />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-12 col-md-6">
                  <div className="hetel-detail">
                    <p>
                      If you feel that your vessel will not fit in Blue Marlin
                      Cove you are welcome to stay at <b>Old Bahama Bay</b>
                    </p>
                    <a
                      href="https://www.oldbahamabayresorts.com/"
                      target="_blank"
                    >
                      Check Availability
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="img-container">
                    <img src={OldBahamaBayLogo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Information;
