import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";
import GetService from "../components/GetService";
import AboutUs from "../assets/images/about-us.png";
import MiniNavbar from "../components/MiniNavbar";
import VideoPlayer from "../components/VideoPlayer";

export default function About() {
  return (
    <div>
      <MiniNavbar />
      <MainNavbar />
      {/* Our About */}

      <div className="container mt-5">
        <div className="text-center">
          <h1 className="main-h1">
            We provide perfect <br></br>IT Solutions & Technology <br></br>
            for any Business
          </h1>
          <div className="w-10 d-flex justify-content-center">
            <p className="main-p w-75">
              We specialize in providing comprehensive IT solutions and advanced
              technology tailored for your Business. Join our innovative team
              and be a part of our mission to empower businesses for success!
            </p>
          </div>
          <VideoPlayer />
          <div></div>
        </div>

        <div className="text-center text-lg-start d-flex  flex-column flex-lg-row gap-5">
          <div className="main-left ">
            <h1 className="about-h1">
              Our Aim &&nbsp;<span className="about-span">Philosophy</span>
            </h1>

            <p className="main-p w-50">
              At CyberGen, our philosophy is rooted in the belief that
              collaboration and unity are key to achieving success. When you
              partner with us, we become one team, working tirelessly to ensure
              your success becomes our success.
            </p>
            <ul className="fw-bold">
              <li>Collaboration & Unity as Key Drivers of Success</li>
              <li className="mt-3 ">
                Tireless Commitment to Shared Accomplishments
              </li>
              <li className="mt-3 ">Partnership Creates a Unified Team</li>
            </ul>
          </div>
          <div className="solution-img">
            <img src={AboutUs} alt="fish" width="300" height="300" />
          </div>
        </div>

        {/* Get Service */}
        <GetService />
      </div>

      <Footer />
    </div>
  );
}
