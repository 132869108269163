import React, { useRef } from "react";
import FlyZoneAboutUs from "../assets/videos/flyzone-about-us.mp4";

const VideoPlayer = () => {
  const videoRef = useRef(null);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <video ref={videoRef} width="600" controls>
        <source src={FlyZoneAboutUs} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
