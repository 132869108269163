import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";
import GetService from "../components/GetService";
import Phone from "../assets/images/phone.png";
import Location from "../assets/images/location.png";
import Bag from "../assets/images/bag.png";
import Earth from "../assets/images/earth.png";
import Earth01 from "../assets/images/earth01.png";
import Map from "../assets/images/map.png";
import MiniNavbar from "../components/MiniNavbar";

export default function Contact() {
  return (
    <div>
      <MiniNavbar />
      <MainNavbar />
      {/* Our Contact */}
      <div className="container mt-5">
        <div className="text-center">
          <div className="main-h1">Lets Start a&nbsp;Conversation</div>
          <div className="w-10 d-flex justify-content-center">
            <p className="main-p w-75">
              You will find yourself working in a true partnership that results
              in an incredibleexperience and deliver the best possible end
              product.
            </p>
          </div>
          <div>
            <h1 className="main-h1 contact-h1">Connect With Us </h1>
            {/* Form */}
            <form className="form-container">
              <div className="row">
                <div className="col text-start">
                  <label className="mb-2">Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="col text-start">
                  <label className="mb-2">Email</label>

                  <input type="email" className="form-control" />
                </div>
                <div className="col-12 mt-4">
                  <input type="text" className="form-control text-check" />
                </div>
                <div className="text-start d-flex gap-2  mt-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label>I agree to our friendly privacy policy</label>
                </div>
              </div>
              <button className="contact-button">Send Request</button>
            </form>
          </div>
          <div className="contact-icons d-flex justify-content-center ">
            <div className="contact-icon-01">
              <img src={Phone} width="60" />
              <p>+1-800-561-2713</p>
            </div>
            <div className="contact-icon-01">
              <img src={Location} width="60" />
              <p>
                Florida Office 2875 S Ocean Blvd,<br></br> Ste. 200 Palm Beach,
                FL 33480
              </p>
            </div>
            <div className="contact-icon-01">
              <img src={Phone} width="60" />
              <p>info@cybergen.com</p>
            </div>
          </div>
          <div className="contact-icons d-flex justify-content-center">
            <div className="contact-icon-01">
              <img src={Bag} width="70" />
              <p>
                Over a Decade <br></br>
                of Experience
              </p>
            </div>
            <div className="contact-icon-01">
              <img src={Earth} width="60" />
              <p>
                Spanning Across 5 <br></br>
                Continents
              </p>
            </div>
            <div className="contact-icon-01">
              <img src={Earth01} width="60" />
              <p>
                Global Footprint in 9+ <br></br> Countries
              </p>
            </div>
          </div>
          <h1 id="footprint-h1">
            Global&nbsp;<span>Footprints</span>{" "}
          </h1>
          <div>
            <img src={Map} width="1200" />
          </div>
        </div>

        {/* Get Service */}
        <GetService />
      </div>

      <Footer />
    </div>
  );
}
