import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Menu from "../assets/images/menu.png";
import Close from "../assets/images/close.png";

const EventsNavbar = () => {
  const location = useLocation();

  return (
    <div className="container events-navbar">
      <div className="nav">
        <nav className="navbar navbar-expand-lg">
          <Link className="nav-anchor" to="/">
            <img src={Logo} alt="flyzone" width="190"  />
          </Link>
          <button
            className="navbar-toggler custom-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="custom-toggler-icon">
              <span className="menu-icon">
                <img src={Menu} alt="Menu" width="50" />
              </span>
              <span className="close-icon">
                <img src={Close} alt="Close" width="30" />
              </span>
            </div>
          </button>
          {/* Navbar links, aligned to the right */}
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
              <div className="nav-links">
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/events/wahoo" && "active-link"
                  }`}
                  to="/events/wahoo"
                >
                  Information
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/events/wahoo-open" && "active-link"
                  }`}
                  to="/events/wahoo-open"
                >
                  Wahoo Open
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/events/championship" &&
                    "active-link"
                  }`}
                  to="/events/championship"
                >
                  Championship
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/events/rules" && "active-link"
                  }`}
                  to="/events/rules"
                >
                  Rules
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/events/resort-and-marina" &&
                    "active-link"
                  }`}
                  to="/events/resort-and-marina"
                >
                  Resort & Marina
                </Link>
                <Link
                  className={`nav-anchor ${
                    location.pathname === "/events/gallery" && "active-link"
                  }`}
                  to="/events/gallery"
                >
                  Gallery
                </Link>
                <Link
                  className={`nav-btn ${
                    location.pathname === "/events/Register" && "active-link"
                  }`}
                  to="/events/Register"
                >
                  Register
                </Link>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default EventsNavbar;
