import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";
import GetService from "../components/GetService";
import Solution1 from "../assets/images/solution.png";
import MiniNavbar from "../components/MiniNavbar";
import { motion } from "framer-motion";
import Typical from "react-typical";
export default function Solution() {
  return (
    <div>
      <MiniNavbar />
      <MainNavbar />
      {/* Our Solution */}
      <div className="our-solutions-page">
        <div className="text-center">
          <h1 className="main-title">
            We provide perfect<br></br>IT Solutions & Technology<br></br> for
            any Business
          </h1>
          <p className="main-des">
            Every business deserves solutions that match its ambition. At
            Flyzone, Our IT expertise and<br></br> innovative technology empower
            organizations to overcome challenges, seize new<br></br>
            opportunities, and thrive in today’s competitive environment. Be
            part of a journey <br></br>where success is redefined!
          </p>
        </div>

        <div className="our-solution-section" id="about-us-section">
          <div className="container my-5">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="details-box">
                  <h2 className="title">
                    Sports <span>App</span>
                  </h2>
                  <p className="des">
                    Flyzone redefines how sports organizations manage events and
                    performance tracking. Our Sports App is designed to simplify
                    scoring systems, streamline event registration, and provide
                    efficient management solutions. Whether it’s keeping scores
                    accurate or managing complex event logistics, our app
                    ensures you stay organized, efficient, and focused on what
                    matters most—delivering an exceptional sports experience.
                  </p>
                  <ul>
                    <li>Real-time performance tracking</li>
                    <li>Streamlined Event Registration</li>
                    <li>Manage schedules, logistics, and more</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="img-box">
                  <motion.img
                    src={Solution1}
                    alt="Fish"
                    className="main-img"
                    initial={{ x: "400%" }}
                    animate={{
                      x: "0%",
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 50,
                      damping: 20,
                      duration: 5,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetService />
      <Footer />
    </div>
  );
}
